import "./App.css";
import PeanutImg from "./images/peanut.webp";
import HenryImg from "./images/henry.webp";

function AboutPage() {
    return (
        <div>
            <div className="about-bg">
                <div className="about-heading-mask">
                    <h1 className="about-heading">About Us</h1>
                </div>
            </div>
            <div className="container">
                <div className="about-content">
                    <h3>Born Out of <span className="blue-text">Passion</span></h3>
                    <p>
                    Peanut Bay Web was born out of a deep passion for technology and creativity. 
                    Our journey began with a small team of dedicated developers and designers who believed 
                    in the transformative potential of the web. Fueled by our love for innovation and a 
                    desire to help businesses succeed, we serve small local businesses around Australia. 
                    <br/>
                    <br/>
                    Based in Homebush, Sydney, we are committed to supporting local businesses in 
                    achieving their digital goals.
                    </p>
                    <br/>
                    <h3>Specially Designed for <span className="blue-text">Small Businesses</span></h3>
                    <p>
                    At Peanut Bay Web, we understand the unique challenges that small businesses face in the digital landscape. 
                    That’s why we offer a tailored monthly subscription service that provides comprehensive 
                    web development and support, ensuring you’re never left on your own all for as little as $50 a month.
                    <br/>
                    <br/>
                    With our subscription model, you get the peace of mind knowing that your digital presence 
                    is being expertly managed, allowing you to focus on what you do best. Our affordable pricing ensures that even the smallest businesses can benefit 
                    from top-tier web development and support without breaking the bank.
                    <br/>
                    <br/>
                    <h3>Ready to<span className="blue-text"> Start?</span></h3>
                    Whether you’re an established business or a small mum and pop shop who are aiming to 
                    expand your digital footprint, we are here to help. Contact us today 
                    to discuss your project and discover how we can turn your vision into a digital masterpiece.
                    </p>
                    <div className="text-center">
                        <a href="/contact">
                            <button className="btn btn-outline-dark btn-lg">Contact Us</button>
                        </a>
                    </div>
                    <br/>
                    <br/>
                    <div className="row">
                        <div className="text-center col-md-6">
                            <img src={HenryImg} className="about-peanut-img" />
                            <h4>Henry Luu</h4>
                            <p>Co-Founder | Developer</p>
                        </div>
                        <div className="text-center col-md-6">
                            <img src={PeanutImg} className="about-peanut-img" />
                            <h4>Peanut Cookie</h4>
                            <p>Co-Founder | Head of Treats</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage;