import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home.js";
import ContactUsPage from "./ContactUsPage.js";
import AboutPage from "./AboutPage.js";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="contact" element={<ContactUsPage />} />
          <Route path="about" element={<AboutPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
