import "./App.css";
import SmartPhoneIcon from "./images/icons/smartphone.webp";
import WindowIcon from "./images/icons/web-design.webp";
import MoneyIcon from "./images/icons/money-bag.webp";
import WorkplaceImg from "./images/workplace.webp";
import DeviceDemoImg from "./images/imac-iphone-demo.webp";
import CheckboxImg from "./images/icons/checkbox.webp";
import ContactUs from "./ContactUs";

function Home() {
  const contactUsBtnHandle = () =>{
    document.getElementById("contact-us").scrollIntoView();
  }

  return (
    <div>
      <div className="landing jumbotron p-5 bg-image">
        <div className="container">
          <div className="row">
            <div className="landing-section d-flex h-100 col-md-6 col-12 order-2 order-md-1">
              <div>
                <h1 className="landing-heading">
                  Empowering Small
                  <br />
                  Businesses Online
                </h1>
                <p className="mb-3 landing-text">
                  Transforming your vision into a captivating online presence
                  for as little as $50 a month.
                </p>
                <button className="btn btn-outline-dark btn-lg" onClick={contactUsBtnHandle}>
                  Get Started Today!
                </button>
              </div>
            </div>
            <div className="col-md-6 col-12 order-1 order-md-2">
              <img className="landing-img" src={DeviceDemoImg} />
            </div>
          </div>
        </div>
      </div>
      <div className="container home-content-one">
        <div className="text-center">
          <h1 className="home-content-one-heading">Our Approach</h1>
          <p className="home-content-one-body">
            We will help you elevate your small business online hassle-free with
            our tailored website building and hosting services. We handle
            everything from design to development, with no upfront costs. Focus
            on your core business while we craft your digital presence.
          </p>
          <a href="https://reno.peanutbayweb.com/">
            <button className="btn btn-dark btn-lg home-content-one-price-btn">
              Checkout Our Work
            </button>
          </a>
        </div>
      </div>
      <div className="content-two">
        <div className="container content-two-body">
          <h1 className="text-center content-two-heading">Why Choose Us?</h1>
          <div className="row text-center">
            <div className="col-12 col-lg-4">
              <div className="content-two-card">
                <img
                  className="content-two-card-icon"
                  src={SmartPhoneIcon}
                  alt="Smart phone icon"
                />
                <h4 className="content-two-card-heading">Mobile Friendly</h4>
                <p className="content-two-card-body">
                  We design your site to be mobile-friendly first, then adapt it
                  for desktops for a seamless user experience.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="content-two-card">
                <img
                  className="content-two-card-icon"
                  src={WindowIcon}
                  alt="Window icon"
                />
                <h4 className="content-two-card-heading">Fully Responsive</h4>
                <p className="content-two-card-body">
                  Your website will be responsive across all screen sizes
                  ranging from mobiles to desktops.
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="content-two-card">
                <img
                  className="content-two-card-icon"
                  src={MoneyIcon}
                  alt="Money icon"
                />
                <h4 className="content-two-card-heading">Cost Effective</h4>
                <p className="content-two-card-body">
                  Cost effective pricing to cater to the needs of small
                  businesses all without compromising quality.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container content-three">
        <div className="row home-content-three-row">
          <div className="col-12 col-lg-6">
            <img
              src={WorkplaceImg}
              className="content-three-image"
              alt="Workplace desk image"
            />
          </div>
          <div className="col-12 col-lg-6 home-content-three-col">
            <h2 className="home-content-three-col-heading">
              $0 Upfront, $50 Per Month
              <br />
              12 Month Minimum Contract
            </h2>
            <p className="content-three-body">
              For just $50 a month and no down payment required, you’ll receive
              a standard 5-page website. If you require a more custom site, we
              offer custom solutions and pricing tailored to meet your specific
              needs.
            </p>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>Unlimited Edits</strong>
                    <br />
                    Looking for a change? We'll do it for free.*
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>No Hosting Fees</strong>
                    <br />
                    We will host your site for you at no extra charge.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>Web Development</strong>
                    <br />
                    Professional hand built sites, line by line.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="home-content-three-col-body">
                  <img
                    className="content-three-col-checkbox-img"
                    src={CheckboxImg}
                  />
                  <p>
                    <strong>Free Email Support</strong>
                    <br />
                    Enjoy free email support for your site.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="process-section">
        <div className="container">
          <h1 className="accordion-heading text-center">The Process</h1>
          <p className="text-center accordion-text">Getting your site up and running has never been easier. We’ve streamlined the process into five straightforward sections to ensure a hassle-free experience from start to finish.</p>
          <div className="accordion" id="accordion-process">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <h4>1. Discover</h4>
                </button>
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordion-process">
                <div className="accordion-body">
                  <p>We begin by getting to know your business inside and out.<br/><br/>Our team will learn about the services you provide, your target audience, and your unique selling points. <br/><br/>This in-depth understanding allows us to outline your vision, set strategic goals, and design a layout that perfectly aligns with your business objectives.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <h4>2. Design</h4>
                </button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordion-process">
                <div className="accordion-body">
                  <p>We bring your vision to life with a collaborative design process.<br/><br/>We will work closely with you to create a customised look and feel for your site.<br/><br/>From selecting the perfect colour schemes to perfecting the layout, we ensure that every aspect of your site reflects your brand identity.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <h4>3. Development</h4>
                </button>
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordion-process">
                <div className="accordion-body">
                  <p>Our skilled developers turn your design into a fully functional website.<br/><br/>We ensure seamless performance and responsiveness across all devices.<br/><br/>From coding to testing, we handle every technical detail to bring your site to life.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  <h4>4. Deployment</h4>
                </button>
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordion-process">
                <div className="accordion-body">
                  <p>Once development is complete, we set your website live.<br/><br/>Our team manages the deployment process to ensure a smooth deployment.<br/><br/>We handle all the technical aspects to ensure your site is ready for your audience.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  <h4>5. Support</h4>
                </button>
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordion-process">
                <div className="accordion-body">
                  <p>After your site is live, we provide ongoing maintenance and updates to keep it running smoothly.<br/><br/>From security updates to content changes, our team ensures your site remains up-to-date and performs at its best.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUs backgroundColour="#3a4542" />
    </div>
  );
}

export default Home;
