import "./App.css";

function ContactUs(props) {
    return (
        <div className="contact-us" id="contact-us" style={{backgroundColor: props.backgroundColour}}>
            <div className="container">
            <h1 className="contact-us-heading text-center">Contact Us</h1>
            <p className="text-center">
                Ready to start your project or have a question? Reach out and we'll
                get back to you as soon as possible!
            </p>
            <div className="contact-us-form">
                <form name="contactus" method="post">
                <input type="hidden" name="form-name" value="contactus" />
                <label className="form-label" htmlFor="name"><strong>Name</strong></label>
                <input className="form-control" type="text" id="name" name="name" required />
                <br/>
                <label className="form-label" htmlFor="email"><strong>Email</strong></label> <br />
                <input className="form-control" type="email" id="email" name="email" required />
                <br/>
                <label className="form-label" htmlFor="message"><strong>Message</strong></label> <br />
                <textarea className="form-control" id="message" name="message" rows="4" required></textarea>
                <br/>
                <div className="text-center">
                    <input className="btn btn-outline-light btn-lg" type="submit" value="Send Message" />
                </div>
                </form>
            </div>
            </div>
        </div>
    )
}

export default ContactUs;